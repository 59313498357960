<script setup lang="ts">
import { useNavigation, useAuth } from '#imports';

const { navigationPath } = useNavigation();
const auth = useAuth();
</script>

<template>
  <AppNavbarMobile
    with-drawer
    alternate
    :actions="[{ id: 'login', icon: 'user-circle', to: '/login' }]"
  >
    <template #action>
      <NuxtLink
        v-if="!auth.loggedIn"
        :to="navigationPath('/login')"
        class="flex justify-center items-center text-white h-[40px] w-[40px] mr-10"
      >
        <ApzIcon
          icon="user-circle"
          fixed-width
          class="text-[24px] mt-5"
        />
      </NuxtLink>

      <NuxtLink
        v-else
        :to="navigationPath('/account')"
        external
        no-rel
        class="flex justify-center items-center text-white h-[40px] w-[40px] mr-10"
      >
        <div class="flex justify-center items-center rounded-full w-[27.5px] h-[27.5px] mt-5 bg-primary-dark">
          <p class="text-white font-bold">{{ auth.user?.firstName.slice(0, 1) }}</p>
        </div>
      </NuxtLink>
    </template>
  </AppNavbarMobile>

  <div
    class="bg-gradient-to-b from-primary-alt to-primary-alt-light"
    data-testid="whyAirpaz-container"
  >
    <div class="max-w-[220px] px-15 pb-20">
      <h1 class="text-white mb-5 font-bold">{{ $t('global.homebannertitle') }}</h1>
      <p class="text-small text-white">{{ $t('global.homebannerdesc') }}</p>
    </div>
  </div>

  <main class="bg-white">
    <section class="w-full">
      <div class="py-20 px-15">
        <div class="flex justify-start items-center">
          <NuxtLink
            class="mr-20"
            :to="navigationPath('/flight')"
          >
            <div class="flex flex-col items-center">
              <div
                class="flex justify-center items-center rounded-xl border border-solid border-primary h-[55px] w-[55px]"
                data-testid="product-flight-button"
              >
                <NuxtImg
                  src="/img/icon/logo_flight.png"
                  :alt="$t('global.flight')"
                  width="70"
                  height="70"
                  format="webp"
                  class="w-35 h-35"
                />
              </div>
              <p class="text-small text-gray-darkest mt-5">{{ $t('global.flight') }}</p>
            </div>
          </NuxtLink>

          <NuxtLink
            class="hotel-menu"
            :to="navigationPath('/hotel')"
            external
            no-rel
          >
            <div class="flex flex-col items-center">
              <div
                class="flex justify-center items-center rounded-xl border border-solid border-primary h-[55px] w-[55px]"
                data-testid="product-hotel-button"
              >
                <NuxtImg
                  src="/img/icon/logo_hotel.png"
                  :alt="$t('global.hotel')"
                  width="70"
                  height="70"
                  format="webp"
                  class="w-35 h-35"
                />
              </div>
              <p class="text-small text-gray-darkest mt-5">{{ $t('global.hotel') }}</p>
            </div>
          </NuxtLink>
        </div>
      </div>
    </section>

    <section class="w-full flex justify-center items-center mb-15">
      <SharedPromoCarouselMobile />
    </section>

    <div class="w-full h-5 bg-whiter" />

    <section class="py-20 bg-gradient-to-r from-[#E65245] to-[#ED2A00] mb-20">
      <div class="text-white px-15">
        <h2 class="font-bold mb-3">{{ $t('after_sales_general.manageyourbooking') }}</h2>
        <p class="text-small mb-15">{{ $t('flight.managebookingflightdesc') }}</p>
      </div>

      <div class="flex gap-x-10 overflow-x-scroll scrollbar-hide px-15">
        <SharedManageOrderBox
          v-for="order in ['add_baggage', 'change_flight', 'refund']"
          :key="order"
          :code="order"
          :path="navigationPath({ path: '/order', query: { type: 'flight' } })"
          :data-testid="'manage-' + order + '-container'"
          is-mobile
        />
      </div>
    </section>

    <section class="mb-20">
      <FlightRecommendationMobile />
    </section>

    <section class="mb-20">
      <HotelRecommendationMobile />
    </section>

    <section class="mb-20">
      <HotelSmartSavingMobile />
    </section>

    <section class="mb-20">
      <HotelPopularDestinationMobile />
    </section>

    <section class="mb-20">
      <HotelNearestCountriesMobile />
    </section>

    <div class="w-full h-5 bg-whiter" />

    <section class="px-15 pt-20">
      <SharedNewsletterFormMobile />
    </section>

    <section class="mb-20">
      <SharedAirpazFeaturesMobile :type="['flight', 'hotel']" />
    </section>

    <section class="mb-40">
      <AirlinePartnersMobile :limit="10" />
    </section>

    <section>
      <SharedSeoFooterMobile />
    </section>
  </main>
</template>
